






































// --- Vue & Template imports ---
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import DefaultListItem from './ListItem.vue';

// --- Models ---
import { DrawerItem } from '@/models/App.model';

@Component({
  components: {
    DefaultListItem,
  },
})
export default class DefaultListGroup extends Vue {
  // Makes a "item" a component prop with the default value of false
  @Prop({default: undefined})
  item!: DrawerItem;
}
